<template>
    <div class="practice-content" v-if="!didRerender">
        <BackButton
            v-if="$screenWidth < 960 && !unitId"
            :link="lesson.link"
            :native="true"
            style="margin-top: 75px"
            class="mb-20 mt-50"
        />
        <LoaderBlock :green-color="true" v-if="loading" />

        <QuizUnavailable v-else-if="!isQuizAvailable(quiz)" class="mb-20" :quiz="quiz" />

        <template v-else>
            <PracticeQuizAttempt
                :unit-id="unitId"
                :lesson="lesson"
                :quiz="quiz"
                @rerender="reRerender()"
                v-if="displayAttempt()"
            />
            <shadow-container :class="{ 'hide-border-right-radius': !!unitId }">
                <PracticeQuizPreview
                    :quiz="quiz"
                    :lesson="lesson"
                    :unit-id="unitId"
                    @rerender="reRerender()"
                    v-if="!displayAttempt() && (quiz || (quiz && quiz.type === 'text_graphic_answer'))"
                />

                <template v-if="displayTGA()">
                    <!-- QUIZ TIME LEFT -->
                    <PracticeQuizTimeLeft v-if="quiz.attempt && isTimeLeft(quiz.attempt.left_time_in_seconds)" />

                    <PracticeOnlyTextGraphicAnswersBlock
                        v-if="
                            (quiz.attempt && !isTimeLeft(quiz.attempt.left_time_in_seconds)) ||
                            !quiz.options.timer_duration
                        "
                        :quiz="quiz"
                        :lesson="lesson"
                        :unit-id="unitId"
                    />
                </template>
            </shadow-container>
        </template>
        <div v-if="!unitId" class="practice-content__icon" :class="{ 'practice-content__icon_active': !expanded }">
            <FullScreenIcon v-if="$screenWidth > 960" @click.native="expanded ? collapse() : expand()" />
            <span v-else @click="displayPracticeModal()">
                {{ t("course.list_of_practice") }}
            </span>
        </div>
    </div>
</template>

<script>
import TimerMixin from "@components/Practice/common/timer/_timer"
import PracticeQuizPreview from "./PracticeQuizPreview"
import PracticeQuizAttempt from "./PracticeQuizAttempt"
import FullScreenIcon from "@icons/FullScreenIcon"
import { EventBus } from "~events"
import PracticeOnlyTextGraphicAnswersBlock from "./PracticeOnlyTextGraphicAnswersBlock"
import LoaderBlock from "@components/Loaders/LoaderBlock"
import BackButton from "@components/BackButton"
import ShadowContainer from "@components/Containers/ShadowContainer"
import moment from "moment/moment"
import QuizUnavailable from "@components/Alerts/QuizUnavailable.vue"
import PracticeRepository from "@components/Practice/shared/repository/practice-repository"
import PracticeQuizTimeLeft from "./PracticeQuizTimeLeft.vue"

moment.locale(window.app_current_locale)

export default {
    name: "PracticeContent",
    props: {
        quiz: {
            type: Object,
            default: null
        },
        lesson: {
            type: Object,
            default: null
        },
        unitId: {
            type: Number,
            default: 0
        }
    },
    mixins: [TimerMixin],
    components: {
        PracticeQuizTimeLeft,
        QuizUnavailable,
        ShadowContainer,
        BackButton,
        LoaderBlock,
        PracticeOnlyTextGraphicAnswersBlock,
        FullScreenIcon,
        PracticeQuizAttempt,
        PracticeQuizPreview
    },
    provide() {
        return {
            startAttempt: async ({ lesson_id, quiz_id, unit_id }) => {
                try {
                    const result = await PracticeRepository.attempt({
                        lesson_id,
                        quiz_id,
                        unit_id
                    })

                    if (result) {
                        this.$set(this.quiz, "attempt", result.data)
                        return result.data.root_id || result.data.id
                    }
                } catch (e) {
                    console.log(e)
                }
            }
        }
    },
    data() {
        return {
            didRerender: false,
            expanded: true,
            openAttempt: false,
            loading: false
        }
    },
    created() {
        EventBus.$on("practice:loading", val => {
            this.loading = val
        })
    },
    mounted() {
        this.$nextTick(() => {
            this.checkContent()
        })
    },
    methods: {
        isQuizAvailable(quiz) {
            if (quiz.attempt) {
                return true
            }
            if (!quiz.blocked_info) {
                return true
            }
            return quiz.blocked_info && quiz.blocked_info.is_accessible && quiz.closed_info.is_accessible
        },
        fbqTrack() {
            window.fbqTrack("clickGetAccess")
        },
        formatDate(date) {
            if (!date) return "∞"
            return moment(date).format("DD MMMM, YYYY HH:mm")
        },
        expand() {
            this.expanded = true
            this.checkContent()
            document.querySelector(".practice-menu").style.width = `500px`
        },
        collapse() {
            this.expanded = false
            this.checkContent()
            document.querySelector(".practice-menu").style.width = `0`
        },
        checkContent() {
            this.$emit("content:check", this.expanded)
        },
        displayPracticeModal() {
            EventBus.$emit("practice:display", true)
        },
        displayTGA() {
            return this.quiz && this.quiz.type === "text_graphic_answer"
        },
        displayAttempt() {
            if (!this.quiz) {
                return false
            }

            if (this.displayTGA()) {
                return false
            }

            if (this.quiz.attempt && this.quiz.attempt.status_id === 5) {
                return true
            }

            if (this.quiz.options && this.quiz.options.timer_duration && !this.quiz.attempt) {
                return false
            }

            if (this.quiz?.start_type?.id === 2 && !this.quiz.attempt) {
                return false
            }

            if (!this.quiz.attempt) {
                return true
            }

            return (
                this.quiz &&
                this.quiz.hasOwnProperty("finalize") &&
                this.quiz.finalize &&
                this.quiz &&
                this.quiz.type !== "text_graphic_answer"
            )
        },
        reRerender() {
            this.didRerender = true
            this.$nextTick(() => {
                this.didRerender = false
            })
        }
    },
    watch: {
        quiz() {
            this.didRerender = true
            this.$nextTick(() => {
                this.didRerender = false
            })
        }
    }
}
</script>
<style lang="sass">
.practice-question
    position: relative
    .files-new-view
        margin-bottom: 15px
</style>
<style scoped lang="sass">

.practice-content
    width: 100%
    position: relative
    flex: 1
    height: 100%
    &::v-deep
        .cursor-pointer
            p
                cursor: pointer !important
    .loader-block
        position: absolute
        width: 100%
        height: 100%
        z-index: 5
        background: #ffffff7d

    @media (max-width: 960px)
        border-left: 0
        border-radius: 10px

    &__icon
        cursor: pointer
        top: 20px
        right: 20px
        position: absolute

        &:hover, &_active
            &::v-deep
                path
                    fill: #3965FF

                line
                    stroke: #3965FF
        @media (max-width: 960px)
            height: 46px
            font-size: 14px
            padding-left: 30px
            padding-right: 30px
            box-shadow: 0 4px 10px rgba(128, 158, 191, 0.15)
            border-radius: 8px
            color: #3965FF
            border: 1px solid transparent
            display: flex
            align-items: center
            justify-content: center
            &:hover
                border-color: #3965FF
            @media screen and (max-width: 640px)
                left: 15px
                right: 15px
    .course__lesson_limited
        display: block
        height: auto
        min-height: 180px
        svg
            display: block
            margin: 30px auto 20px
            width: 100px
            height: 70px
            opacity: .4
        p
            font-weight: 400
            font-size: 14px
            margin-top: 20px
</style>
