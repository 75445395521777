<template>
    <h3
        :style="{
            fontSize: `${size}px`,
            lineHeight: `${lineHeight}${isPercentLineHeight ? '%' : 'px'}`,
            fontWeight: weight,
            textAlign,
            color: textColor,
            ...titleStyle
        }"
        class="default-title"
        :class="[customClass, { linked, highlightLinks }]"
    >
        <slot></slot>
    </h3>
</template>

<script>
export default {
    name: "DefaultTitle",
    props: {
        fontFamily: {
            type: String,
            default: null,
            required: false
        },
        color: {
            type: String,
            default: null,
            required: false
        },
        size: {
            type: Number,
            default: 14,
            required: false
        },
        fontSize: {
            type: Number,
            default: 0,
            required: false
        },
        fontWeight: {
            type: Number,
            default: 0,
            required: false
        },
        weight: {
            type: [Number, String],
            default: 700,
            required: false
        },
        lineHeight: {
            type: Number,
            default: 17,
            required: false
        },
        isPercentLineHeight: {
            type: Boolean,
            default: false,
            required: false
        },
        inter: {
            type: Boolean,
            default: false,
            required: false
        },
        large: {
            type: Boolean,
            default: false,
            required: false
        },
        title: {
            type: Boolean,
            default: false,
            required: false
        },
        success: {
            type: Boolean,
            default: false,
            required: false
        },
        blue: {
            type: Boolean,
            default: false,
            required: false
        },
        small: {
            type: Boolean,
            default: false,
            required: false
        },
        descr: {
            type: Boolean,
            default: false,
            required: false
        },
        black: {
            type: Boolean,
            default: false,
            required: false
        },
        linked: {
            type: Boolean,
            default: false,
            required: false
        },
        highlightLinks: {
            type: Boolean,
            default: false,
            required: false
        },
        customClass: {
            type: String,
            default: ""
        },
        marginTop: {
            type: Number,
            default: 0,
            required: false
        },
        marginLeft: {
            type: Number,
            default: 0,
            required: false
        },
        textAlign: {
            type: String,
            default: ""
        },
        textColor: {
            type: String,
            default: ""
        },
        marginBottom: {
            type: Number,
            default: 0,
            required: false
        },
        paddingLeft: {
            type: Number,
            default: 0,
            required: false
        },
        paddingRight: {
            type: Number,
            default: 0,
            required: false
        }
    },
    computed: {
        titleStyle() {
            const style = {}
            if (this.marginTop) style.marginTop = `${this.marginTop}px`
            if (this.marginLeft) style.marginLeft = `${this.marginLeft}px`
            if (this.marginBottom) style.marginBottom = `${this.marginBottom}px`
            if (this.paddingLeft) style.paddingLeft = `${this.paddingLeft}px`
            if (this.paddingRight) style.paddingRight = `${this.paddingRight}px`
            if (this.fontFamily) style.fontFamily = this.fontFamily

            if (
                this.inter ||
                this.large ||
                this.title ||
                this.small ||
                this.descr ||
                this.linked ||
                this.success ||
                this.blue
            ) {
                style.fontFamily = "Inter, sans-serif"
                style.lineHeight = `160%`
            }
            if (this.large) {
                style.color = "#323030"
                style.fontWeight = 500
                style.fontSize = 16
            }
            if (this.title) {
                style.color = "#323030"
                style.fontWeight = 600
                style.fontSize = 16
            }
            if (this.small) {
                style.color = "#52565C"
                style.fontWeight = 400
                style.fontSize = 14
            }
            if (this.descr) {
                style.color = "#52565C"
                style.fontWeight = 400
                style.fontSize = 13
            }
            if (this.linked) {
                style.color = "#3965FF"
                style.fontWeight = 400
                style.fontSize = 13
                style.cursor = "pointer"
                style.width = "max-content"
            }
            if (this.success) {
                style.color = "#15A06E"
                style.fontWeight = 400
                style.fontSize = 13
            }
            if (this.blue) {
                style.color = "#3965FF"
                style.fontWeight = 400
                style.fontSize = 14
            }

            if (this.black) {
                style.color = "hsl(0, 0%, 8%)"
            }

            if (this.fontWeight) {
                style.fontWeight = this.fontWeight
            }

            if (this.color) {
                style.color = this.color
            }

            if (this.fontSize) {
                style.fontSize = `${this.fontSize}px`
            }

            if (this.fontWeight) {
                style.fontWeight = this.fontWeight
            }

            if (style.fontSize && typeof style.fontSize === "number") {
                style.fontSize = `${style.fontSize}px`
            }

            return style
        }
    }
}
</script>

<style scoped lang="sass">
.default-title
    color: hsla(0, 0%, 8%, 1)
    font-family: "Ubuntu", sans-serif
    &.inter
        font-family: "Inter", sans-serif
    b
        font-weight: 600
    .gray
        color: #B5C1D2
        font-weight: 400
    &.linked
        text-underline-offset: 2px
        &:hover
            text-decoration: underline
    &.highlightLinks
        &::v-deep
            a
                text-decoration: none
                color: #3965FF
                &:hover
                    text-decoration: underline

    .blue
        color: #3965FF
        margin-left: 5px
    &::v-deep
        li
            list-style-type: disc
            margin-left: 15px
            margin-top: 5px
        .link
            color: #3965FF
            text-decoration: none
            margin-left: 0
    .black-light
        color: #B5C1D2
        font-weight: 400
    &::v-deep
        b
            font-weight: 600
        strong
            font-weight: 500
</style>
