import { FileTask } from "@components/Files/FileTask"
import { get } from "idb-keyval"
import * as tus from "tus-js-client"
import { v4 } from "uuid"

export class TusFileTask extends FileTask {
    file = null
    result = null
    progress = 0
    fileName = ""
    status = "progress"
    tusUploader = null
    canRetry = true
    constructor(file) {
        super(file, false)

        this.file = file
        this.fileName = file.name

        this.createTus(file).then(() => {
            this.tusUploader.start()
        })
    }

    async createTus(file) {
        let formData = new FormData()
        formData.append("model", await get("file_model"))

        this.tusUploader = new tus.Upload(file, {
            endpoint: window.file_storage_tus_url,
            retryDelays: [0, 3000],
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${window.token}`
            },
            metadata: {
                filename: v4(),
                original_name: file.name,
                mime_type: file.type,
                ...Object.fromEntries(formData)
            },

            // eslint-disable-next-line no-unused-vars
            onShouldRetry: function (err, retryAttempt, options) {
                console.log("Upload Error", err)

                const status = err.originalResponse ? err.originalResponse.getStatus() : 0

                return !(status === 403 || status === 422)
            },
            onError: function (e) {
                this.status = "failed"
                let message = ""
                this.progress = 0

                if (typeof e.originalResponse?.getBody === "function") {
                    try {
                        message = JSON.parse(e.originalResponse.getBody())
                    } catch (e) {
                        message = e
                    }
                } else {
                    message = e
                }

                this.message = message

                if (this.onError) {
                    this.onError(message)
                }
            }.bind(this),
            onProgress: function (bytesUploaded, bytesTotal) {
                this.onProgress({ progress: bytesUploaded / bytesTotal })
            }.bind(this),
            onSuccess: function (payload) {
                const uuid = payload.lastResponse.getHeader("X-File-Uuid")
                this.onSuccess({ uuid: uuid })
            }.bind(this)
        })
    }

    retry() {
        if (this.tusUploader) {
            this.tusUploader.start()
        }
    }

    abort() {
        this.tusUploader.abort()

        if (this.onError) {
            this.status = "aborted"
            this.onError("aborted")
        }
    }

    onProgress(data) {
        this.status = "progress"
        this.progress = parseInt(data?.progress * 100)
    }

    onSuccess(data) {
        this.result = data

        this.status = "checking"
        this.checkStatus()

        this.progress = 100
    }

    onFailed(data) {
        this.status = "failed"
        this.message = data?.message
        this.progress = 0

        if (this.onError) {
            this.onError(data?.message)
        }
    }
}
